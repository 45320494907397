// import React, { useState, useEffect } from "react"
// import PropTypes from "prop-types"
// import {
//   Dropdown,
//   DropdownToggle,
//   DropdownMenu,
//   DropdownItem,
//   Alert,
//   Spinner,
// } from "reactstrap"

// // i18n
// import { withTranslation } from "react-i18next"
// // Redux
// import { connect } from "react-redux"
// import { Link } from "react-router-dom"
// import withRouter from "components/Common/withRouter"

// // users
// import CommonLogic from "components/Common/CommonLogic"

// const ProfileMenu = props => {
//   const [menu, setMenu] = useState(false)
//   const [merchantInfo, setMerchantInfo] = useState(null)
//   const [logoUrl, setLogoUrl] = useState("")
//   const email = localStorage.getItem("email")
//   // Effect to handle merchant info updates
//   useEffect(() => {
//     if (merchantInfo) {
//       const logo = merchantInfo.logo

//       if (typeof logo === "string") {
//         // Directly set the logo if it's a URL string
//         setLogoUrl(logo)
//       } else if (logo?.data) {
//         // Convert buffer to Blob URL if necessary
//         const convertBufferToBlobUrl = bufferData => {
//           const blob = new Blob([new Uint8Array(bufferData)], {
//             type: "image/jpeg", // Adjust MIME type as needed
//           })
//           return URL.createObjectURL(blob)
//         }

//         const generatedLogoUrl = convertBufferToBlobUrl(logo.data)
//         setLogoUrl(generatedLogoUrl)
//       } else {
//         setLogoUrl(null)
//       }
//     }
//   }, [merchantInfo])

//   return (
//     <React.Fragment>
//       <CommonLogic>
//         {({ merchantData, loading, error: merchantError }) => {
//           if (loading)
//             return (
//               <div
//                 style={{
//                   display: "flex",
//                   justifyContent: "center",
//                   alignItems: "center",
//                   height: "50vh",
//                 }}
//               >
//                 <p size="xg" className="me-2" style={{ color: "#2B3A4A" }}>
//                   Loading
//                 </p>
//               </div>
//             )
//           if (merchantError)
//             return <Alert color="danger">{merchantError}</Alert>
//           if (!merchantData || !merchantData._id)
//             return (
//               <Alert color="warning">Merchant data is not available.</Alert>
//             )

//           // Update merchant info once it's available
//           if (merchantData !== merchantInfo) {
//             setMerchantInfo(merchantData)
//           }

//           return (
//             <Dropdown
//               isOpen={menu}
//               toggle={() => setMenu(!menu)}
//               className="d-inline-block"
//             >
//               <DropdownToggle
//                 className="btn header-item waves-effect"
//                 id="page-header-user-dropdown"
//                 tag="button"
//               >
//                 <img
//                   className="rounded-circle header-profile-user"
//                   src={logoUrl || "/default-logo.png"}
//                   alt="Header Avatar"
//                 />
//               </DropdownToggle>
//               <DropdownMenu className="dropdown-menu-end">
//                 <DropdownItem tag="a" href="/merchant-info">
//                   <i className="mdi mdi-account-circle font-size-17 text-muted align-middle me-1" />
//                   {props.t("Merchant Info")}
//                   <br></br>
//                   {email}
//                 </DropdownItem>

//                 <div className="dropdown-divider" />
//                 <Link to="/logout" className="dropdown-item text-danger">
//                   <i className="mdi mdi-power font-size-17 text-muted align-middle me-1 text-danger" />
//                   <span>{props.t("Logout")}</span>
//                 </Link>
//               </DropdownMenu>
//             </Dropdown>
//           )
//         }}
//       </CommonLogic>
//     </React.Fragment>
//   )
// }

// ProfileMenu.propTypes = {
//   success: PropTypes.any,
//   t: PropTypes.any,
// }

// const mapStatetoProps = state => {
//   const { error, success } = state.Profile
//   return { error, success }
// }

// export default withRouter(
//   connect(mapStatetoProps, {})(withTranslation()(ProfileMenu)),
// )

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Alert,
} from "reactstrap"

// i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// users
import CommonLogic from "components/Common/CommonLogic"

const ProfileMenu = props => {
  const [menu, setMenu] = useState(false)
  const [merchantInfo, setMerchantInfo] = useState(null)
  const [logoUrl, setLogoUrl] = useState("")
  const email = localStorage.getItem("email")

  // Fetch and set the logo URL when `merchantInfo` updates
  useEffect(() => {
    if (merchantInfo) {
      const logo = merchantInfo.logo

      if (typeof logo === "string") {
        // Directly set the logo if it's a URL string
        setLogoUrl(logo)
      } else if (logo?.data) {
        // Convert buffer to Blob URL if necessary
        const convertBufferToBlobUrl = bufferData => {
          const blob = new Blob([new Uint8Array(bufferData)], {
            type: "image/jpeg", // Adjust MIME type as needed
          })
          return URL.createObjectURL(blob)
        }

        const generatedLogoUrl = convertBufferToBlobUrl(logo.data)
        setLogoUrl(generatedLogoUrl)
      } else {
        setLogoUrl("/default-logo.png") // Fallback to default logo
      }
    }
  }, [merchantInfo])

  // Automatically refresh merchant data using polling
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (merchantInfo) {
        console.log("Refreshing merchant data...")
        // Trigger any re-fetching or state updates here if needed
      }
    }, 10000) // Refresh every 10 seconds

    return () => clearInterval(intervalId) // Cleanup on unmount
  }, [merchantInfo])

  return (
    <React.Fragment>
      <CommonLogic>
        {({ merchantData, loading, error: merchantError }) => {
          if (loading)
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "50vh",
                }}
              >
                <p size="xg" className="me-2" style={{ color: "#2B3A4A" }}>
                  Loading...
                </p>
              </div>
            )
          if (merchantError)
            return <Alert color="danger">{merchantError}</Alert>
          // if (!merchantData || !merchantData._id)
          //   return (
          //     <Alert color="warning">Merchant data is not available.</Alert>
          //   )

          // Update merchant info only if it's different
          if (merchantData !== merchantInfo) {
            setMerchantInfo(merchantData)
          }

          return (
            <Dropdown
              isOpen={menu}
              toggle={() => setMenu(!menu)}
              className="d-inline-block"
            >
              <DropdownToggle
                className="btn header-item waves-effect"
                id="page-header-user-dropdown"
                tag="button"
              >
                <img
                  className="rounded-circle header-profile-user"
                  src={logoUrl || "/default-logo.png"}
                  alt="Header Avatar"
                  onError={() => setLogoUrl("/default-logo.png")} // Handle broken image URLs
                />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem tag="a" href="/merchant-info">
                  <i className="mdi mdi-account-circle font-size-17 text-muted align-middle me-1" />
                  {props.t("Merchant Info")}
                  <br />
                  {email}
                </DropdownItem>

                <div className="dropdown-divider" />
                <Link to="/logout" className="dropdown-item text-danger">
                  <i className="mdi mdi-power font-size-17 text-muted align-middle me-1 text-danger" />
                  <span>{props.t("Logout")}</span>
                </Link>
              </DropdownMenu>
            </Dropdown>
          )
        }}
      </CommonLogic>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu)),
)
