import React, { useEffect, useState } from "react"
import { Card, CardBody, CardText, Row, Col, Button } from "reactstrap"
import { QRCodeCanvas } from "qrcode.react"
import html2pdf from "html2pdf.js" // Import html2pdf.js
import dummyQRcode from "../../assets/images/dummyQRcode.png"
const MonthlyEarnings = ({ merchantData }) => {
  const [logoUrl, setLogoUrl] = useState("")
  const [scannerUrl, setScannerUrl] = useState("")
  const baseUrl =
    process.env.REACT_APP_ENV === "production"
      ? "https://postaireview.com/"
      : "https://test.postaireview.com/"

  useEffect(() => {
    // // Convert logo buffer to URL if needed
    if (typeof merchantData?.logo === "string") {
      setLogoUrl(merchantData.logo)
    } else if (merchantData?.logo?.data) {
      const convertBufferToBlobUrl = bufferData => {
        const blob = new Blob([new Uint8Array(bufferData)], {
          type: "image/jpeg", // Adjust MIME type as needed
        })
        return URL.createObjectURL(blob)
      }
      setLogoUrl(convertBufferToBlobUrl(merchantData.logo.data))
    }

    // Convert scanner buffer to URL if needed
    if (typeof merchantData?.scanner === "string") {
      setScannerUrl(merchantData.scanner)
    } else if (merchantData?.scanner?.data) {
      const convertBufferToUrl = () => `${baseUrl}${merchantData.businessname}`
      setScannerUrl(convertBufferToUrl())
    }
  }, [merchantData])

  // const downloadCardPDF = () => {
  //   const element = document.getElementById("pdf-content") // Get the content to be converted to PDF
  //   const options = {
  //     // Adjust margin for better control over content
  //     filename: `${merchantData.businessname}_QR.pdf`,
  //     image: { type: "jpeg", quality: 0.98 },
  //     html2canvas: { scale: 2 },
  //     jsPDF: {
  //       unit: "mm",
  //       format: [100, 180], // Custom size (width, height in mm)
  //       orientation: "portrait",
  //     },
  //   }

  //   // Use html2pdf to generate and download the PDF
  //   html2pdf()
  //     .from(element) // Capture content from the specified element
  //     .set(options)
  //     .save()
  // }
  const downloadCardPDF = () => {
    const element = document.getElementById("pdf-content"); // Get the content to be converted to PDF
  
    // Get the dimensions of the content in mm
    const elementWidth = element.offsetWidth * 0.264583; // Convert px to mm (1 px = 0.264583 mm)
    const elementHeight = element.offsetHeight * 0.264583;
  
    // Configure options for html2pdf
    const options = {
      margin: [0, 0], // Remove all margins
      filename: `${merchantData.businessname}_QR.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: {
        scale: 2, // Higher scale for better quality
        useCORS: true, // Handle cross-origin images
        scrollX: 0, // Prevent extra space caused by scrollbars
        scrollY: 0,
      },
      jsPDF: {
        unit: "mm",
        format: [elementWidth, elementHeight], // Use dynamic content dimensions
        orientation: "portrait",
      },
    };
  
    // Use html2pdf to generate and download the PDF
    html2pdf()
      .from(element)
      .set(options)
      .toPdf()
      .get("pdf")
      .then((pdf) => {
        // Check for excess blank pages
        const totalPages = pdf.internal.getNumberOfPages();
        if (totalPages > 1) {
          // Remove all blank pages except the first one
          pdf.deletePage(totalPages);
        }
      })
      .save();
  };
  
  

  return (
    <React.Fragment>
      <Row>
        <Col md="4">
        <CardText
  className="mb-3"
  style={{
    fontSize: "0.9rem",
    lineHeight: "1.1",
    color: "#000000",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    gap: "8px",
    border: `1px solid ${merchantData.themeColor}`,
    borderRadius: "8px",
    padding: "10px",
    backgroundColor: "#ffffff",
    wordBreak: "break-word",
  }}
>
  <div style={{ marginBottom: "8px", fontSize: "0.7rem", color: "#666666" }}>
    This is your unique URL. You can share it with your users to generate reviews.
  </div>

  {merchantData.businessname ? (
    <>
      <span style={{ flex: 1 }}>{`${baseUrl}${merchantData.businessname}`}</span>
      <div style={{ textAlign: "center" }}>
        <i
          className="mdi mdi-content-copy"
          style={{
            fontSize: "20px",
            cursor: "pointer",
            color: merchantData.themeColor,
          }}
          onClick={(e) => {
            navigator.clipboard.writeText(`${baseUrl}${merchantData.businessname}`);
            const feedback = document.createElement("div");
            feedback.textContent = "Copied!";
            feedback.style.fontSize = "0.6rem";
            feedback.style.color = "green";
            feedback.style.marginTop = "3px";
            e.target.parentNode.appendChild(feedback);

            setTimeout(() => {
              if (feedback.parentNode) {
                feedback.parentNode.removeChild(feedback);
              }
            }, 2000);
          }}
        ></i>
      </div>
    </>
  ) : (
    <span style={{ flex: 1, color: "#ff0000" }}>No URL available</span>
  )}
</CardText>






          <div id="pdf-content">
            {scannerUrl ? (
              <Card
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderLeft: `10px solid ${merchantData.themeColor}`,
                  borderRight: `10px solid ${merchantData.themeColor}`,
                }}
              >
                <CardBody style={{ textAlign: "center" }}>
                  {logoUrl ? (
                    <img
                      src={logoUrl || "placeholder-image-url.jpg"}
                      alt="Company Logo"
                      style={{ maxWidth: "100px", height: "auto" }}
                    />
                  ) : (
                    <p>Logo not available</p>
                  )}
                  <CardText
                    className="mb-2"
                    style={{
                      fontSize: "1.2rem",
                      fontWeight: "bold",
                      color: merchantData.textColor,
                      lineHeight: "1.0",
                      marginTop: "10px",
                    }}
                  >
                    PLEASE TAKE A MIN AND
                  </CardText>
                  <CardText
                    className="mb-2"
                    style={{
                      color: merchantData.textColor,
                      fontWeight: "bold",
                      fontSize: "1.2rem",
                      lineHeight: "1.1",
                    }}
                  >
                    REVIEW US
                  </CardText>
                  <div className="mt-1">
                    {scannerUrl && (
                      <QRCodeCanvas
                        value={scannerUrl}
                        size={200}
                        level="H"
                        includeMargin={true}
                      />
                    )}
                  </div>
                  <CardText
                    className="mb-3"
                    style={{
                      fontSize: "1rem",
                      fontWeight: "bold",
                      lineHeight: "1.1",
                      color: merchantData.textColor,
                    }}
                  >
                    WITH OUR ALL NEW
                  </CardText>
                  <CardText className="mb-2">
                    <span
                      style={{
                        backgroundColor: merchantData.themeColor,
                        fontWeight: "bold",
                        color: merchantData.textColor,
                        padding: "0 10px",
                        fontSize: "1.2rem",
                        lineHeight: "1.1",
                      }}
                    >
                      AI POWERED REVIEW
                    </span>
                  </CardText>
                  <CardText className="mb-1" style={{ fontSize: "1rem" }}>
                    <small>Powered By: PostReview</small>
                  </CardText>

                  
                </CardBody>
              </Card>
              
              
            ) :
             (

              
              <Card
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "20px 0",
                }}
              >
                <img
                  src={dummyQRcode}
                  alt="Dummy QR Code"
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    maxHeight: "600px",
                    objectFit: "contain",
                  }}
                />
              </Card>
            )}
          </div>
         
        </Col>
        <Col md="8">
          <Card>
            <CardBody>
              <h4 className="card-title mb-4" style={{ fontSize: "1.2rem" }}>
                Follow These Steps to Unlock Your QR Code
              </h4>
              <ol className="activity-feed mb-0">
                <li className="feed-item">
                  <div className="feed-item-list">
                    <span className="date" style={{ fontSize: "1rem" }}>
                      Step 1 : Merchant Information
                    </span>
                    <span
                      className="activity-text"
                      style={{ fontSize: "0.95rem" }}
                    >
                      Provide your business details to get started.{" "}
                      <a
                        href="/merchant-info"
                        style={{ textDecoration: "none", color: "blue" }}
                      >
                        Click here
                      </a>{" "}
                      to fill out the Merchant Information form. (Google Review
                      Link must be active)
                    </span>
                  </div>
                </li>
                <li className="feed-item">
                  <div className="feed-item-list">
                    <span className="date" style={{ fontSize: "1rem" }}>
                      Step 2 : Fill AI Form
                    </span>
                    <span
                      className="activity-text"
                      style={{ fontSize: "0.95rem" }}
                    >
                      Add the questions and relevant keywords to set up
                      AI-powered review generation.<br></br>{" "}
                      <a
                        href="/AI-Form"
                        style={{ textDecoration: "none", color: "blue" }}
                      >
                        Click here
                      </a>{" "}
                      to complete the AI Form.
                    </span>
                  </div>
                </li>
                <li className="feed-item">
                  <div className="feed-item-list">
                    <span className="date" style={{ fontSize: "1rem" }}>
                      Step 3 : Subscribe
                    </span>
                    <span
                      className="activity-text"
                      style={{ fontSize: "0.95rem" }}
                    >
                      Unlock all features by subscribing to our platform.{" "}
                      <a
                        href="/subscription-plans"
                        style={{ textDecoration: "none", color: "blue" }}
                      >
                        Click here
                      </a>{" "}
                      to choose a subscription plan.
                    </span>
                  </div>
                </li>
                <li className="feed-item">
                  <div className="feed-item-list">
                    <span className="date" style={{ fontSize: "1rem" }}>
                      Step 4 : Download QR Code PDF
                    </span>
                    <span
                      className="activity-text"
                      style={{ fontSize: "0.95rem" }}
                    >

                      <Button
                      color="primary"
                      onClick={downloadCardPDF}
                      style={{ marginTop: "10px" }}
                       >
              Download QR PDF
                   </Button>
                    </span>
                  </div>
                </li>
              </ol>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default MonthlyEarnings
