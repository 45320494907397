import { Formik, Form } from "formik"
import {
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormFeedback,
  Row,
  Col,
  Alert,
  Spinner,
  FormGroup,
} from "reactstrap"
import * as Yup from "yup"

import axios from "axios"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "../../store/actions"
import React, { useState, useRef, useEffect } from "react"
import { SketchPicker } from "react-color"
import Switch from "react-switch"

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
// Validation schema using Yup
const MerchantInfos = props => {
  document.title = "MerchantInfos | PostReview"

  const breadcrumbItems = [
    { title: "PostReview", link: "#" },
    { title: "MerchantInfos", link: "MerchantInfos-page" },
  ]
  const baseUrl =
    process.env.REACT_APP_ENV === "production"
      ? "https://postaireview.com/"
      : "https://test.postaireview.com/"

  const email = localStorage.getItem("email")
  const token = localStorage.getItem("token")
  const [logoUrl, setLogoUrl] = useState(null)
  const [merchantData, setMerchantData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [logoPreview, setLogoPreview] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const toggleModal = () => setModalOpen(!modalOpen)
  const [themeColor, setThemeColor] = useState("")
  const [textColor, setTextColor] = useState("")
  const [showThemePicker, setShowThemePicker] = useState(false)
  const [showTextPicker, setShowTextPicker] = useState(false)
  const [hasGST, setHasGST] = useState(true)
  const [submitMessage, setSubmitMessage] = useState("")
  const [switch1, setSwitch1] = useState(true)
  const themePickerRef = useRef(null)
  const textPickerRef = useRef(null)
  const [isActiveSubscription, setIsActiveSubscription] = useState(false)
  const [currentLogo, setCurrentLogo] = useState("")

  const [initialValues, setInitialValues] = useState({
    logo: null,
    themeColor: "",
    textColor: "",
    googleReviewLink: "",
    negativeReviewMessage: "",
    name: "",
    businessName: "",
    businessCategory: "",
    email: email,
    mobileno: "",
    scanner: "",
    gstNumber: "",
    isActiveSubscription: false,
    negativeReviewProtectiontoggle: true, // Default value for the toggle
    SubscriptionStartDate: "",
    SubscriptionEndDate: "",
    address: {
      country: "",
      state: "",
      city: "",
      line: "",
    },
  })
  console.log(initialValues)
  useEffect(() => {
    props.setBreadcrumbItems("MerchantInfos", breadcrumbItems)

    const fetchMerchantData = async () => {
      try {
        if (!email) {
          setError("Email is required")
          return
        }

        const response = await axios.get(
          `${API_BASE_URL}/merchantsinfos/${email}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          },
        )

        if (response && response.data) {
          const data = response.data
          // Parse the address JSON string into an object
          // const parsedAddress = data.address
          //   ? JSON.parse(data.address)
          //   : { country: "", state: "", city: "", line: "" }
          let parsedAddress = { country: "", state: "", city: "", line: "" }

          if (data.address) {
            try {
              parsedAddress =
                typeof data.address === "string"
                  ? JSON.parse(data.address)
                  : data.address
            } catch (error) {
              console.error("Error parsing address:", error)
            }
          }
          // Handle logo conversion
          let convertedLogoUrl = ""
          if (data.logo) {
            if (typeof data.logo === "string") {
              convertedLogoUrl = data.logo
            } else if (data.logo.data) {
              const convertBufferToBlobUrl = bufferData => {
                const blob = new Blob([new Uint8Array(bufferData)], {
                  type: "image/jpeg",
                })
                return URL.createObjectURL(blob)
              }
              convertedLogoUrl = convertBufferToBlobUrl(data.logo.data)
            }
          }

          // Set state with fetched data and processed logo
          setMerchantData(data)
          setLogoUrl(convertedLogoUrl)

          // Update states based on fetched data
          setInitialValues({
            logo: convertedLogoUrl || "",
            themeColor: data.themeColor || "",
            textColor: data.textColor || "",
            googleReviewLink: data.googlereviewURL || "",
            negativeReviewMessage: data.negativeRevireMessage || "",
            name: data.name || "",
            businessName: data.businessname || "",
            businessCategory: data.businessCategory || "",
            email: data.email || "",
            mobileno: data.mobileno || "",
            scanner: data.scanner?.data || "",
            gstNumber: data.gstNumber || "",
            isActiveSubscription: data.isActiveSubscription ?? false,
            SubscriptionStartDate: data.SubscriptionStartDate || "",
            SubscriptionEndDate: data.SubscriptionEndDate || "",

            negativeReviewProtectiontoggle:
              data.negativeReviewProtectiontoggle ?? true, // Default to true if not provided
            // address: {
            //   country: parsedAddress.country || "",
            //   state: parsedAddress.state || "",
            //   city: parsedAddress.city || "",
            //   line: parsedAddress.line || "",
            // },
            address: parsedAddress, // ✅ Fix: Address properly set
          })

          // Set hasGST state based on the response
          setHasGST(data.hasGST ?? true)

          // Set the switch state based on the response
          setSwitch1(data.negativeReviewProtectiontoggle ?? true)
        } else {
          setError("No data found")
        }
      } catch (error) {
        console.error("Error fetching merchant data:", error)
        setError("Failed to fetch merchant data")
      } finally {
        setLoading(false)
      }
    }

    if (email) {
      fetchMerchantData()
    }
    const handleClickOutside = event => {
      if (
        themePickerRef.current &&
        !themePickerRef.current.contains(event.target)
      ) {
        setShowThemePicker(false)
      }
      if (
        textPickerRef.current &&
        !textPickerRef.current.contains(event.target)
      ) {
        setShowTextPicker(false)
      }
    }

    // Add event listener for mouse click
    document.addEventListener("mousedown", handleClickOutside)

    // Cleanup the event listener when component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [email, token])
  const businessCategories = [
    "Retail",
    "Food & Beverage",
    "Services",
    "Health & Wellness",
    "Technology",
    "Education",
    "Entertainment",
    "Other",
  ]

  // Validation schema
  const validationSchema = Yup.object({
    logo: Yup.mixed().required("Logo is required"),
    name: Yup.string()
      .required("Name is required")
      .matches(/^[a-zA-Z\s]+$/, "Name must only contain letters and spaces"),

    businessName: Yup.string()
      .required("Business Name is required")
      .test(
        "uniqueBusinessName",
        "Business name already exists",
        async (value, context) => {
          if (!value) return true

          try {
            const email = context?.parent?.email // Assuming `email` is part of the form data
            const response = await axios.post(`${API_BASE_URL}/check-unique`, {
              businessName: value,
              email,
            })

            return response.status === 200
          } catch (error) {
            if (error.response && error.response.status === 409) {
              return false
            }

            throw new Error("Server error during business name validation")
          }
        },
      ),
    address: Yup.object({
      country: Yup.string().required("Country is required"),
      state: Yup.string().required("State is required"),
      city: Yup.string().required("City is required"),
      line: Yup.string().required("Address Line is required"),
    }),
    businessCategory: Yup.string().required("Business Category is required"),
    googleReviewLink: Yup.string()
      .required("Google Review Link is required")
      .url("Enter a valid URL"),
    negativeReviewMessage: Yup.string().required(
      "Negative Review Message is required",
    ),
    gstNumber: hasGST
      ? Yup.string()
          .required("GST Number is required")
          .test(
            "uniqueGstNumber",
            "GST Number already exists",
            async (value, context) => {
              if (!value) return true

              try {
                const email = context?.parent?.email // Assuming `email` is part of the form data
                const response = await axios.post(
                  `${API_BASE_URL}/check-unique`,
                  {
                    gstNumber: value,
                    email,
                  },
                )

                return response.status === 200
              } catch (error) {
                if (error.response && error.response.status === 409) {
                  return false
                }

                throw new Error("Server error during GST Number validation")
              }
            },
          )
      : Yup.string(),
  })

  const handleLogoChange = (event, setFieldValue, setLogoUrl) => {
    const file = event.target.files[0] // Get the uploaded file

    if (file) {
      const img = new Image()
      const reader = new FileReader()

      reader.onloadend = () => {
        img.onload = () => {
          const maxWidth = 500
          const maxHeight = 500

          if (img.width > maxWidth || img.height > maxHeight) {
            // ✅ Resize if the image exceeds 500x500px
            const canvas = document.createElement("canvas")
            const ctx = canvas.getContext("2d")

            const scalingFactor = Math.min(
              maxWidth / img.width,
              maxHeight / img.height,
            )

            canvas.width = img.width * scalingFactor
            canvas.height = img.height * scalingFactor

            ctx.drawImage(img, 0, 0, canvas.width, canvas.height)

            canvas.toBlob(
              blob => {
                if (blob) {
                  const resizedFile = new File([blob], file.name, {
                    type: file.type,
                  })

                  setFieldValue("logo", resizedFile) // ✅ Store resized image in Formik
                  const blobUrl = URL.createObjectURL(blob) // ✅ Create preview URL
                  setLogoUrl(blobUrl)

                  // Cleanup old Blob URL if needed
                  if (logoUrl) {
                    URL.revokeObjectURL(logoUrl)
                  }
                }
              },
              file.type,
              1, // Max quality
            )
          } else {
            // ✅ Use original image if within 500x500px
            setFieldValue("logo", file)
            setLogoUrl(reader.result)
          }
        }

        img.onerror = () => {
          alert("The uploaded file is not a valid image.")
          setFieldValue("logo", null)
          setLogoUrl(null)
        }

        img.src = reader.result // ✅ Load the image
      }

      reader.onerror = () => {
        alert("An error occurred while reading the file.")
        setFieldValue("logo", null)
        setLogoUrl(null)
      }

      reader.readAsDataURL(file) // ✅ Convert to DataURL for preview
    } else {
      setFieldValue("logo", null)
      setLogoUrl(null)
    }
  }

  const handleThemeColorChange = (color, setFieldValue) => {
    setThemeColor(color.hex)
    setFieldValue("themeColor", color.hex)
  }

  const handleTextColorChange = (color, setFieldValue) => {
    setTextColor(color.hex)
    setFieldValue("textColor", color.hex)
  }

  // const handleSubmit = async (values, { setSubmitting }) => {
  //   const formData = new FormData()

  //   // ✅ Preserve the existing logo if no new file is uploaded
  //   if (values.logo && values.logo instanceof File) {
  //     formData.append("logo", values.logo)
  //   } else if (logoUrl) {
  //     formData.append("logoUrl", logoUrl) // Use the existing logo URL
  //   }

  //   formData.append("themeColor", values.themeColor)
  //   formData.append("textColor", values.textColor)
  //   formData.append("googlereviewURL", values.googleReviewLink)
  //   formData.append("negativeRevireMessage", values.negativeReviewMessage)
  //   formData.append("name", values.name)
  //   formData.append("businessname", values.businessName)
  //   formData.append("email", email)
  //   formData.append("mobileno", values.mobileno)
  //   formData.append("businessCategory", values.businessCategory)
  //   formData.append("isActiveSubscription", values.isActiveSubscription)
  //   formData.append("gstNumber", hasGST ? values.gstNumber : "")
  //   formData.append("hasGST", hasGST)
  //   formData.append("SubscriptionStartDate", values.SubscriptionStartDate)
  //   formData.append("SubscriptionEndDate", values.SubscriptionEndDate)
  //   formData.append("negativeReviewProtectiontoggle", switch1)

  //   const addressData = values.address || {
  //     country: "",
  //     state: "",
  //     city: "",
  //     line: "",
  //   }
  //   formData.append("address", JSON.stringify(addressData))

  //   try {
  //     const response = await axios.put(
  //       `${API_BASE_URL}/merchantsinfosByEmail/${email}`,
  //       formData,
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       },
  //     )

  //     console.log("Submission Response:", response.data)
  //     setSubmitMessage("Update Data Successfully!")

  //     // ✅ Fetch updated data to ensure the logo remains
  //     const updatedData = await axios.get(
  //       `${API_BASE_URL}/merchantsinfos/${email}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Content-Type": "application/json",
  //         },
  //       },
  //     )

  //     if (updatedData && updatedData.data) {
  //       const data = updatedData.data
  //       let updatedLogoUrl = logoUrl

  //       // ✅ Ensure logo is fetched correctly from the API
  //       if (data.logo) {
  //         if (typeof data.logo === "string") {
  //           updatedLogoUrl = data.logo
  //         } else if (data.logo.data) {
  //           const convertBufferToBlobUrl = bufferData => {
  //             const blob = new Blob([new Uint8Array(bufferData)], {
  //               type: "image/jpeg",
  //             })
  //             return URL.createObjectURL(blob)
  //           }
  //           updatedLogoUrl = convertBufferToBlobUrl(data.logo.data)
  //         }
  //       }

  //       setLogoUrl(updatedLogoUrl) // ✅ Ensure logo is set correctly
  //       setMerchantData(data)

  //       setInitialValues(prevValues => ({
  //         ...prevValues,
  //         logo: updatedLogoUrl || prevValues.logo, // ✅ Preserve the existing logo
  //         themeColor: data.themeColor || "",
  //         textColor: data.textColor || "",
  //         googleReviewLink: data.googlereviewURL || "",
  //         negativeReviewMessage: data.negativeRevireMessage || "",
  //         name: data.name || "",
  //         businessName: data.businessname || "",
  //         businessCategory: data.businessCategory || "",
  //         email: data.email || "",
  //         mobileno: data.mobileno || "",

  //         address: data.address
  //           ? JSON.parse(data.address)
  //           : {
  //               country: "",
  //               state: "",
  //               city: "",
  //               line: "",
  //             },
  //       }))
  //     }
  //   } catch (error) {
  //     console.error(
  //       "Error updating merchant data:",
  //       error.response || error.message,
  //     )
  //     setSubmitMessage(
  //       error.response?.data?.message || "Submission failed. Please try again.",
  //     )
  //   } finally {
  //     setSubmitting(false)
  //   }
  // }

  const handleSubmit = async (values, { setSubmitting }) => {
    const formData = new FormData()

    // ✅ Preserve the existing logo if no new file is uploaded
    if (values.logo && values.logo instanceof File) {
      formData.append("logo", values.logo)
    } else if (logoUrl) {
      formData.append("logoUrl", logoUrl) // Use the existing logo URL
    }

    formData.append("themeColor", values.themeColor)
    formData.append("textColor", values.textColor)
    formData.append("googlereviewURL", values.googleReviewLink)
    formData.append("negativeRevireMessage", values.negativeReviewMessage)
    formData.append("name", values.name)
    formData.append("businessname", values.businessName)
    formData.append("email", email)
    formData.append("mobileno", values.mobileno)
    formData.append("businessCategory", values.businessCategory)
    formData.append("isActiveSubscription", values.isActiveSubscription)
    formData.append("gstNumber", hasGST ? values.gstNumber : "")
    formData.append("hasGST", hasGST)
    formData.append("SubscriptionStartDate", values.SubscriptionStartDate)
    formData.append("SubscriptionEndDate", values.SubscriptionEndDate)
    formData.append("negativeReviewProtectiontoggle", switch1)
    formData.append("scanner", `${baseUrl}${values.businessName}`)

    // ✅ Preserve address correctly
    // const addressData = values.address
    //   ? values.address
    //   : {
    //       country: "",
    //       state: "",
    //       city: "",
    //       line: "",
    //     }
    // formData.append("address", JSON.stringify(addressData))

    // ✅ Ensure address is always stored properly
    const addressData = values.address || {
      country: "",
      state: "",
      city: "",
      line: "",
    }
    formData.append("address", JSON.stringify(addressData))
    try {
      const response = await axios.put(
        `${API_BASE_URL}/merchantsinfosByEmail/${email}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        },
      )

      console.log("Submission Response:", response.data)
      setSubmitMessage("Update Data Successfully!")

      // ✅ Fetch updated data to ensure the logo and address remain
      const updatedData = await axios.get(
        `${API_BASE_URL}/merchantsinfos/${email}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        },
      )

      if (updatedData && updatedData.data) {
        const data = updatedData.data
        let updatedLogoUrl = logoUrl

        // ✅ Ensure logo is fetched correctly from the API
        if (data.logo) {
          if (typeof data.logo === "string") {
            updatedLogoUrl = data.logo
          } else if (data.logo.data) {
            const convertBufferToBlobUrl = bufferData => {
              const blob = new Blob([new Uint8Array(bufferData)], {
                type: "image/jpeg",
              })
              return URL.createObjectURL(blob)
            }
            updatedLogoUrl = convertBufferToBlobUrl(data.logo.data)
          }
        }

        // ✅ Fix: Ensure Address is Parsed Properly Before Updating State
        // let parsedAddress = { country: "", state: "", city: "", line: "" }
        // if (data.address) {
        //   try {
        //     parsedAddress =
        //       typeof data.address === "string"
        //         ? JSON.parse(data.address)
        //         : data.address
        //   } catch (error) {
        //     console.error("Error parsing address:", error)
        //   }
        // }

        // ✅ Fix: Ensure Address is Parsed Properly Before Updating State
        let parsedAddress = { country: "", state: "", city: "", line: "" }
        if (data.address) {
          try {
            parsedAddress =
              typeof data.address === "string"
                ? JSON.parse(data.address)
                : data.address
          } catch (error) {
            console.error("Error parsing address:", error)
          }
        }

        setLogoUrl(updatedLogoUrl) // ✅ Ensure logo is set correctly
        setMerchantData(data)

        setInitialValues(prevValues => ({
          ...prevValues,
          logo: updatedLogoUrl || prevValues.logo, // ✅ Preserve the existing logo
          themeColor: data.themeColor || "",
          textColor: data.textColor || "",
          googleReviewLink: data.googlereviewURL || "",
          negativeReviewMessage: data.negativeRevireMessage || "",
          name: data.name || "",
          businessName: data.businessname || "",
          businessCategory: data.businessCategory || "",
          email: data.email || "",
          mobileno: data.mobileno || "",
          // address: parsedAddress, // ✅ Use properly parsed address
          address: parsedAddress, // ✅ Fix: Use properly parsed address
        }))
      }
    } catch (error) {
      console.error(
        "Error updating merchant data:",
        error.response || error.message,
      )
      setSubmitMessage(
        error.response?.data?.message || "Submission failed. Please try again.",
      )
    } finally {
      setSubmitting(false)
    }
  }

  const handleGstToggle = () => {
    setHasGST(prev => !prev)
    if (!hasGST) {
      setInitialValues(prev => ({ ...prev, gstNumber: "" }))
    }
  }

  const OnSymbol = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
      }}
    >
      On
    </div>
  )

  const OffSymbol = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
      }}
    >
      Off
    </div>
  )
  console.log("logoUrl", logoUrl)
  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <Spinner size="lg" className="me-2" style={{ color: "#2B3A4A" }} />
          Loading...
        </div>
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize // Ensures the form updates when initialValues change
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            isSubmitting,
          }) => (
            <Form
              style={{
                backgroundColor: "white",
                padding: "20px",
                borderRadius: "5px",
              }}
            >
              <Row>
                <Col md="8">
                  <div className="form-group mt-3">
                    <Label>Upload Your Logo</Label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Input
                        type="file"
                        accept="image/*"
                        name="logo"
                        onChange={event =>
                          handleLogoChange(event, setFieldValue, setLogoUrl)
                        }
                        id="logoUpload"
                        style={{ display: "none" }}
                      />

                      <Button
                        color="primary"
                        onClick={() =>
                          document.getElementById("logoUpload").click()
                        }
                      >
                        Upload Logo
                      </Button>
                      <span style={{ marginLeft: "10px" }}>
                        {logoUrl ? "Logo uploaded!" : "No logo uploaded."}
                      </span>
                      <div
                        style={{
                          width: "80px",
                          height: "80px",
                          border: "1px solid #ccc",
                          borderRadius: "3px",
                          marginLeft: "20px",
                          backgroundColor: "#f5f5f5",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {logoUrl ? (
                          <img
                            src={logoUrl}
                            alt="Logo Preview"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                            }}
                          />
                        ) : (
                          <span style={{ color: "#ccc" }}>No logo</span>
                        )}
                      </div>
                    </div>
                    {touched.logo && errors.logo && (
                      <FormFeedback className="d-block">
                        {errors.logo}
                      </FormFeedback>
                    )}
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="8">
                  {/*Name Field */}
                  <div className="mb-3">
                    <Label htmlFor="name">
                      Name
                      <span
                        style={{
                          marginLeft: "6px",
                        }}
                      >
                        ( Name of your Outlet, Shop Hospital, etc. )
                      </span>
                    </Label>
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Enter your Name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={touched.name && !!errors.name}
                    />
                    {touched.name && errors.name && (
                      <FormFeedback>{errors.name}</FormFeedback>
                    )}
                  </div>

                  {/* Business Name Field */}
                  <div className="mb-3">
                    <Label htmlFor="businessName">
                      Business Name
                      <span
                        style={{
                          marginLeft: "6px",
                        }}
                      >
                        ( Unique name based on which we will generate te QR Code
                        URL)
                      </span>
                    </Label>
                    <Input
                      type="text"
                      name="businessName"
                      id="businessName"
                      placeholder="Enter your Business Name"
                      value={values.businessName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={touched.businessName && !!errors.businessName}
                    />
                    {touched.businessName && errors.businessName && (
                      <FormFeedback>{errors.businessName}</FormFeedback>
                    )}
                  </div>

                  {/* Business Category Dropdown */}
                  <div className="mb-3">
                    <Label htmlFor="businessCategory">Business Category</Label>
                    <Input
                      type="select"
                      name="businessCategory"
                      id="businessCategory"
                      value={values.businessCategory}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={
                        touched.businessCategory && !!errors.businessCategory
                      }
                    >
                      <option value="" label="Select business category" />
                      {businessCategories.map((category, index) => (
                        <option key={index} value={category}>
                          {category}
                        </option>
                      ))}
                    </Input>
                    {touched.businessCategory && errors.businessCategory && (
                      <FormFeedback>{errors.businessCategory}</FormFeedback>
                    )}
                  </div>

                  {/* GST Number Field */}
                  <div className="mb-3">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        onChange={handleGstToggle}
                        className="form-check-input"
                        checked={!hasGST}
                      />
                      <label className="form-check-label mb-3">
                        I don't have a GST number
                      </label>
                    </div>

                    <Label htmlFor="gstNumber">GST Number</Label>
                    <Input
                      id="gstNumber"
                      name="gstNumber"
                      type="text"
                      placeholder="Enter GST number"
                      value={values.gstNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={!hasGST}
                      invalid={touched.gstNumber && !!errors.gstNumber}
                    />
                    {touched.gstNumber && errors.gstNumber && (
                      <FormFeedback>{errors.gstNumber}</FormFeedback>
                    )}
                  </div>
                  {/* Email Field */}
                  <div className="mb-3">
                    <Label htmlFor="email">Email</Label>
                    <Input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Enter your Email"
                      value={email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={touched.email && !!errors.email}
                      readOnly
                      style={{ backgroundColor: "#f0f0f0" }}
                    />
                    {touched.email && errors.email && (
                      <FormFeedback>{errors.email}</FormFeedback>
                    )}
                  </div>

                  <div className="mb-3">
                    <Label htmlFor="mobileno">Mobile Number</Label>
                    <Input
                      type="number"
                      name="mobileno"
                      id="mobileno"
                      placeholder="Enter your mobile number"
                      value={values.mobileno}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={touched.mobileno && !!errors.mobileno}
                    />
                    {touched.mobileno && errors.mobileno && (
                      <FormFeedback>{errors.mobileno}</FormFeedback>
                    )}
                  </div>
                </Col>
              </Row>
              <Row form>
                <Label for="address">Address</Label>
                <Col md={2}>
                  <FormGroup>
                    <Input
                      type="text"
                      name="address.line"
                      id="address.line"
                      placeholder="Address Line"
                      value={values.address?.line || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={touched.address?.line && !!errors.address?.line}
                    />
                    {touched.address?.line && errors.address?.line && (
                      <FormFeedback>{errors.address.line}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FormGroup>
                    <Input
                      type="text"
                      name="address.city"
                      id="address.city"
                      placeholder="city"
                      value={values.address?.city || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={touched.address?.city && !!errors.address?.city}
                    />
                    {touched.address?.city && errors.address?.city && (
                      <FormFeedback>{errors.address.city}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FormGroup>
                    <Input
                      type="text"
                      id="address.state"
                      placeholder="State"
                      name="address.state"
                      value={values.address?.state || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={
                        touched.address?.state && !!errors.address?.state
                      }
                    />
                    {touched.address?.state && errors.address?.coustatentry && (
                      <FormFeedback>{errors.address.state}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FormGroup>
                    <Input
                      type="text"
                      // name="address.country"
                      id="address.country"
                      placeholder="Country"
                      // value={values.address.country}
                      // onChange={handleChange}
                      name="address.country"
                      value={values.address?.country || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={
                        touched.address?.country && !!errors.address?.country
                      }
                    />
                    {touched.address?.country && errors.address?.country && (
                      <FormFeedback>{errors.address.country}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="8">
                  {/* Theme and Text Color Fields */}
                  <div
                    className="mb-3"
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      flexWrap: "wrap",
                    }}
                  >
                    <div style={{ marginRight: "20px", flex: "1 1 200px" }}>
                      <Label>Theme Color</Label>
                      <Input
                        type="text"
                        placeholder="Select Theme Color"
                        onClick={() => setShowThemePicker(!showThemePicker)}
                        value={values.themeColor}
                        readOnly
                        invalid={touched.themeColor && !!errors.themeColor}
                      />
                      {showThemePicker && (
                        <div ref={themePickerRef}>
                          <SketchPicker
                            color={themeColor}
                            onChangeComplete={color =>
                              handleThemeColorChange(color, setFieldValue)
                            }
                          />
                        </div>
                      )}
                      {touched.themeColor && errors.themeColor ? (
                        <FormFeedback style={{ display: "block" }}>
                          {errors.themeColor}
                        </FormFeedback>
                      ) : null}

                      <Label className="mt-3">Text Color</Label>
                      <Input
                        type="text"
                        placeholder="Select Text Color"
                        onClick={() => setShowTextPicker(!showTextPicker)}
                        value={values.textColor}
                        readOnly
                        invalid={touched.textColor && !!errors.textColor}
                      />
                      {showTextPicker && (
                        <div ref={textPickerRef}>
                          <SketchPicker
                            color={textColor}
                            onChangeComplete={color =>
                              handleTextColorChange(color, setFieldValue)
                            }
                          />
                        </div>
                      )}
                      {touched.textColor && errors.textColor ? (
                        <FormFeedback style={{ display: "block" }}>
                          {errors.textColor}
                        </FormFeedback>
                      ) : null}
                    </div>

                    {/* Preview Button */}
                    <div
                      style={{
                        marginTop: "20px",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        padding: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flex: "1 1 150px",
                      }}
                    >
                      <Button
                        disabled
                        style={{
                          backgroundColor: themeColor,
                          borderColor: themeColor,
                          color: textColor,
                          marginRight: "10px",
                        }}
                      >
                        Post Review
                      </Button>
                      <div
                        style={{
                          borderLeft: "1px solid #ccc",
                          height: "60px",
                          margin: "0 10px",
                        }}
                      ></div>
                      <div
                        style={{
                          fontSize: "12px",
                          color: "#000",
                          fontWeight: "lighter",
                        }}
                      >
                        This is a preview button and it will be visible to the
                        person submitting review.
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="8">
                  {/* Google Review Link Field */}

                  <div className="mb-3">
                    <Label htmlFor="googleReviewLink">
                      Google Review Link
                      <span
                        onClick={toggleModal}
                        style={{
                          color: "#7A6FBE",
                          cursor: "pointer",
                          marginLeft: "6px",
                        }}
                      >
                        (Click here for help)
                      </span>
                    </Label>
                    <Input
                      type="url"
                      name="googleReviewLink"
                      id="googleReviewLink"
                      placeholder="Enter your Google Review Link"
                      value={values.googleReviewLink}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={
                        touched.googleReviewLink && !!errors.googleReviewLink
                      }
                    />
                    {touched.googleReviewLink && errors.googleReviewLink && (
                      <FormFeedback>{errors.googleReviewLink}</FormFeedback>
                    )}

                    <Modal isOpen={modalOpen} toggle={toggleModal}>
                      <ModalHeader toggle={toggleModal}>
                        Steps to Get Google Review URL
                      </ModalHeader>
                      <ModalBody>
  <ol>
    <li>
      Log in to your Google account using Google Chrome.
    </li>
    <li>
      In the Google search bar, type 'My Business' and press Enter.
    </li>
    <li>
      Locate your business in the search results and click on the <b>View Profile</b> button.
    </li>
    <li>
      On your business profile page, look for the <b>Ask for Reviews</b> option and click on it.
    </li>
    <li>
      Google will display your review link and provide an option to copy it.
    </li>
    <li>
      Copy the review link and paste it into the Merchant Info form.
    </li>
    </ol>
    <ol>
      <b>OR</b><br />
      <a href="https://www.heroesofdigital.com/tools/google-review-link-generator/" target="_blank" rel="noopener noreferrer">
        click here
      </a>{" "}
      to generate a Google review link quickly.
    </ol>
 
</ModalBody>


                      <ModalFooter>
                        <Button color="secondary" onClick={toggleModal}>
                          Close
                        </Button>
                      </ModalFooter>
                    </Modal>
                  </div>

                  {/* Negative Review Message */}
                  <div className="mb-3">
                    <Label htmlFor="negativeReviewMessage">
                      Negative Review Message
                    </Label>
                    <Input
                      type="textarea"
                      name="negativeReviewMessage"
                      id="negativeReviewMessage"
                      placeholder="Enter message for negative reviews"
                      value={values.negativeReviewMessage}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={
                        touched.negativeReviewMessage &&
                        !!errors.negativeReviewMessage
                      }
                    />
                    {touched.negativeReviewMessage &&
                      errors.negativeReviewMessage && (
                        <FormFeedback>
                          {errors.negativeReviewMessage}
                        </FormFeedback>
                      )}
                  </div>

                  {/* Negative Review Protection Switch */}
                  <div className="form-group mt-3">
                    <Label className="form-label">
                      Enable Negative Review Protection
                    </Label>
                    <br />
                    <Switch
                      uncheckedIcon={<OffSymbol />}
                      checkedIcon={<OnSymbol />}
                      onColor="#7A6FBE"
                      onChange={() => setSwitch1(!switch1)}
                      checked={switch1}
                    />
                  </div>
                </Col>
              </Row>

              <Button
                color="primary"
                type="submit"
                className="mt-3 mb-3"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <>
                    <Spinner size="sm" className="me-2" />
                    Submitting...
                  </>
                ) : (
                  "Submit"
                )}
              </Button>
              {submitMessage && <Alert color="success">{submitMessage}</Alert>}
            </Form>
          )}
        </Formik>
      )}
    </>
  )
}

export default connect(null, { setBreadcrumbItems })(MerchantInfos)