import React, { useEffect, useState } from "react"
import { Row, Col, Card, Spinner } from "reactstrap"
import { connect } from "react-redux"
import Miniwidget from "./Miniwidget"
import MonthlyEarnings from "./montly-earnings"
import { setBreadcrumbItems } from "../../store/actions"
import axios from "axios"

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const Dashboard = props => {
  document.title = "Dashboard | PostReview"

  const breadcrumbItems = [
    { title: "PostReview", link: "#" },
    { title: "Dashboard", link: "/dashboard" },
  ]

  const [merchantData, setMerchantData] = useState(null)
  const [allReviewsCount, setAllReviewsCount] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const email = localStorage.getItem("email")
  const token = localStorage.getItem("token")

  useEffect(() => {
    props.setBreadcrumbItems("Dashboard", breadcrumbItems)

    const fetchMerchantData = async () => {
      try {
        if (!email) {
          setError("Email is required.")
          return
        }

        const response = await axios.get(
          `${API_BASE_URL}/merchantsinfos/${email}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          },
        )

        if (response && response.data) {
          setMerchantData(response.data)
        } else {
          setError("No merchant data found.")
        }
      } catch (error) {
        console.error("Error fetching merchant data:", error)
        setError("Failed to fetch merchant data.")
      } finally {
        setLoading(false)
      }
    }

    fetchMerchantData()
  }, [email, token, props])

  useEffect(() => {
    if (merchantData?._id) {
      fetchAllReviewsCount(merchantData._id)
    }
  }, [merchantData])

  const fetchAllReviewsCount = async () => {
    if (!merchantData._id) return
    try {
      const response = await axios.get(
        `${API_BASE_URL}/reviews/count/${merchantData._id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Pass token in header
            "Content-Type": "application/json",
          },
        },
      )

      if (response && response.data) {
        setAllReviewsCount(response.data) // Set fetched data to state
      } else {
        setError("No data found")
      }
    } catch (error) {
      console.error("Error fetching reviews count:", error)
      setError("Failed to fetch reviews count")
    } finally {
      setLoading(false)
    }
  }

  const reports = [
    {
      title: "Positive Reviews",
      iconClass: "thumb-up",
      total: allReviewsCount.positiveReviews,
      average: "+15%",
      badgecolor: "success",
    },
    {
      title: "Negative Reviews",
      iconClass: "thumb-down",
      total: allReviewsCount.negativeReviews,
      average: "-5%",
      badgecolor: "danger",
    },
    {
      title: "Total QR Code Scans",
      iconClass: "qrcode-scan",
      total: allReviewsCount.positiveReviews + allReviewsCount.negativeReviews,
      average: "+20%",
      badgecolor: "primary",
    },
  ]
  console.log("allReviewsCount", allReviewsCount)
  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <Spinner size="lg" className="me-2" style={{ color: "#2B3A4A" }} />
        Loading...
      </div>
    )
  }

  if (error) {
    return <p>{error}</p>
  }

  return (
    <React.Fragment>
      {/* Mini widgets */}
      <Miniwidget reports={reports} />
      <hr />
      <Row>
        <Col xl="12">
          {/* Monthly Earnings */}
          <MonthlyEarnings merchantData={merchantData} />
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(Dashboard)
