import React, { useEffect, useState } from "react"
import {
  Card,
  CardText,
  CardTitle,
  CardBody,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Container,
  Row,
  Col,
  Spinner,
  Alert,
  Button,
} from "reactstrap"
import CommonLogic from "components/Common/CommonLogic"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "../../store/actions"
import axios from "axios"
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
const SubscriptionPlans = props => {
  document.title = "Subscription Plans | PostReview"

  const breadcrumbItems = [
    { title: "PostReview", link: "#" },
    { title: "Subscription Plans", link: "Subscription Plans" },
  ]
  const token = localStorage.getItem("token")
  const [merchantInfo, setMerchantInfo] = useState(null)
  const [loadingPayment, setLoadingPayment] = useState(false)
  const [subscriptionData, setSubscriptionData] = useState("")
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  console.log("subscriptionData", subscriptionData)
  useEffect(() => {
    props.setBreadcrumbItems("SubscriptionPlans", breadcrumbItems)

    // Load Razorpay script
    const scriptId = "razorpay-embed-btn-js"
    const loadRazorpayScript = () => {
      if (!document.getElementById(scriptId)) {
        const script = document.createElement("script")
        script.defer = true
        script.id = scriptId
        script.src = "https://cdn.razorpay.com/static/embed_btn/bundle.js"
        script.onload = () => {
          const rzp = window["__rzp__"]
          if (rzp && rzp.init) rzp.init()
        }
        document.body.appendChild(script)
      } else {
        const rzp = window["__rzp__"]
        if (rzp && rzp.init) rzp.init()
      }
    }

    loadRazorpayScript()
    fetchSubscriptionInfo()
  }, [props, merchantInfo])

  useEffect(() => {
    // Reinitialize Razorpay buttons after merchantInfo updates
    const rzp = window["__rzp__"]
    if (rzp && rzp.init) rzp.init()
    // handleSubscription("monthly")
  }, [merchantInfo])

  const fetchSubscriptionInfo = async () => {
    if (!merchantInfo?._id) {
      console.error("Merchant ID is missing!")
      setError("Merchant information is not available.")
      return
    }

    try {
      setLoading(true)

      // Fetch subscription data
      const response = await axios.get(
        `${API_BASE_URL}/subscription/${merchantInfo._id}`,
      )

      console.log("Fetched subscription:", response.data) // Log the response

      // Set the subscription data based on the response type
      if (Array.isArray(response.data)) {
        setSubscriptionData(response.data)
      } else {
        setSubscriptionData([response.data]) // Wrap single object in an array
      }
    } catch (err) {
      // Log and set error message
      console.error("Error fetching subscription data:", err.message)
      setError(
        err.response?.data?.message || "Failed to fetch subscription data.",
      )
    } finally {
      setLoading(false) // Stop loading spinner
    }
  }

  const activePlan = subscriptionData?.[0]

  const handleSubscription = async plan => {
    if (!merchantInfo || !merchantInfo._id) {
      alert("Merchant information is missing. Please try again.")
      console.error("Merchant information is not available:", merchantInfo)
      return false // Indicate failure
    }

    setLoadingPayment(true)

    const payload = {
      merchantInfoId: merchantInfo._id,
      Plan: plan, // 'monthly' or 'yearly'
    }

    try {
      // Call the subscription API
      const response = await axios.post(
        `${API_BASE_URL}/subscription`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        },
      )

      if (response.status === 201) {
        console.log("Subscription created successfully:", response.data)

        return true // Indicate success
      } else {
        alert("Failed to create subscription. Please try again.")
        console.error("API Response Error:", response)
        return false // Indicate failure
      }
    } catch (error) {
      console.error("Error creating subscription:", error)
      alert(
        `An error occurred while creating the subscription: ${
          error.response?.data?.message || error.message
        }`,
      )
      return false // Indicate failure
    } finally {
      setLoadingPayment(false)
    }
  }
  console.log("subscriptionData", subscriptionData)
  return (
    <CommonLogic>
      {({ merchantData, loading, error: merchantError }) => {
        if (loading)
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
              }}
            >
              <Spinner
                size="xg"
                className="me-2"
                style={{ color: "#2B3A4A" }}
              />
              Loading...
            </div>
          )
        if (merchantError) return <Alert color="danger">{merchantError}</Alert>
        if (!merchantData || !merchantData._id)
          return <Alert color="warning">Merchant data is not available.</Alert>

        // Update merchant info once fetched
        if (merchantData && !merchantInfo) {
          setMerchantInfo(merchantData)
        }

        const Monthly = `https://pages.razorpay.com/pl_PgXDe3CLkcIn5G/view?email=${merchantInfo?.email}&phone=${merchantInfo?.mobileno}&GSTNo=${merchantInfo?.gstNumber}}`
        const yearly = `https://pages.razorpay.com/pl_PgXKE41BASkQbV/view?email=${merchantInfo?.email}&phone=${merchantInfo?.mobileno}&GSTNo=${merchantInfo?.gstNumber}}`
        console.log("Monthly", Monthly)

        return (
          <Container className="mt-4">
            <Row className="mb-2">
              <Col xs="12">
                <Card className="text-center border-0 shadow-sm">
                  {/* <CardBody>
                    <CardTitle tag="h1" className="mb-4 text-primary">
                      Active Plan
                    </CardTitle>
                    {merchantInfo.isActiveSubscription ? (
                      <>
                        <CardText>
                          <strong className="text-primary">Plan Name:</strong>{" "}
                          {activePlan.Plan || "N/A"}
                        </CardText>
                        <CardText>
                          <strong className="text-primary">Start Date:</strong>{" "}
                          {activePlan.StartDate
                            ? new Date(
                                activePlan.StartDate,
                              ).toLocaleDateString()
                            : "N/A"}
                        </CardText>
                        <CardText>
                          <strong className="text-primary"> End Date: </strong>{" "}
                          {activePlan.EndDate
                            ? new Date(activePlan.EndDate).toLocaleDateString()
                            : "N/A"}
                        </CardText>
                      </>
                    ) : (
                      <CardText
                        style={{ color: "#dc3545", fontWeight: "bold" }}
                      >
                        No Active Plan Found
                      </CardText>
                    )}
                  </CardBody> */}
                  <CardBody>
                    <CardTitle tag="h1" className="mb-4 text-primary">
                      Active Plan
                    </CardTitle>
                    {merchantInfo?.isActiveSubscription ? (
                      <>
                        <CardText>
                          <strong className="text-primary">Plan Name:</strong>{" "}
                          {activePlan?.Plan || "N/A"}
                        </CardText>
                        <CardText>
                          <strong className="text-primary">Start Date:</strong>{" "}
                          {activePlan?.StartDate &&
                          !isNaN(Date.parse(activePlan.StartDate))
                            ? new Date(
                                activePlan.StartDate,
                              ).toLocaleDateString()
                            : "N/A"}
                        </CardText>
                        <CardText>
                          <strong className="text-primary">End Date:</strong>{" "}
                          {activePlan?.EndDate &&
                          !isNaN(Date.parse(activePlan.EndDate))
                            ? new Date(activePlan.EndDate).toLocaleDateString()
                            : "N/A"}
                        </CardText>
                      </>
                    ) : (
                      <CardText
                        style={{ color: "#dc3545", fontWeight: "bold" }}
                      >
                        No Active Plan Found
                      </CardText>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col xs="12" md="6" lg="6">
                <Card>
                  <CardHeader
                    className="bg-primary text-white text-center"
                    style={{ fontWeight: "bold" }}
                  >
                    Features
                  </CardHeader>
                  <CardBody>
                    <ListGroup flush>
                      <ListGroupItem className="text-center">
                        Unlimited reviews/month
                      </ListGroupItem>
                      <ListGroupItem className="text-center">
                        Unlimited negative review protection
                      </ListGroupItem>
                      <ListGroupItem className="text-center">
                        Comprehensive support for your queries
                      </ListGroupItem>
                      <ListGroupItem className="text-center">
                        Theme Color Customization
                      </ListGroupItem>
                      <ListGroupItem className="text-center">
                        Negative Review Insights
                      </ListGroupItem>
                      <ListGroupItem className="text-center">
                        View data & connect with customers to improve business
                      </ListGroupItem>
                      <ListGroupItem className="text-center">
                        Easy to use dashboard and data monitoring
                      </ListGroupItem>
                    </ListGroup>
                  </CardBody>

                  {merchantInfo && (
                    <div className="d-flex justify-content-evenly flex-wrap">
                      {/* Monthly Plan Button */}
                      <button
                        className="btn btn-primary"
                        disabled={
                          activePlan?.Plan === "monthly" &&
                          activePlan?.EndDate &&
                          new Date(activePlan.EndDate) > new Date()
                        }
                        onClick={async () => {
                          try {
                            const isSubscriptionCreated =
                              await handleSubscription("monthly")
                            if (isSubscriptionCreated) {
                              console.log(
                                "Subscription created, opening Razorpay payment page...",
                              )
                              window.open(Monthly, "_blank") // Open Razorpay payment page in a new tab
                            }
                          } catch (error) {
                            console.error(
                              "Error during subscription or payment flow:",
                              error,
                            )
                          }
                        }}
                        style={{
                          flex: "0 0 48%",
                          marginBottom: "15px",
                          padding: "10px 20px",
                          backgroundColor:
                            activePlan?.Plan === "monthly" &&
                            activePlan?.EndDate &&
                            new Date(activePlan.EndDate) > new Date()
                              ? "gray"
                              : "",
                          cursor:
                            activePlan?.Plan === "monthly" &&
                            activePlan?.EndDate &&
                            new Date(activePlan.EndDate) > new Date()
                              ? "not-allowed"
                              : "pointer",
                        }}
                      >
                        Purchase Monthly @ ₹350
                      </button>

                      {/* Annual Plan Button */}
                      <button
                        className="btn btn-primary"
                        disabled={
                          activePlan?.Plan === "yearly" &&
                          activePlan?.EndDate &&
                          new Date(activePlan.EndDate) > new Date()
                        }
                        onClick={async () => {
                          try {
                            const isSubscriptionCreated =
                              await handleSubscription("yearly")
                            if (isSubscriptionCreated) {
                              console.log(
                                "Subscription created, opening Razorpay payment page...",
                              )
                              window.open(yearly, "_blank") // Open Razorpay payment page in a new tab
                            }
                          } catch (error) {
                            console.error(
                              "Error during subscription or payment flow:",
                              error,
                            )
                          }
                        }}
                        style={{
                          flex: "0 0 48%",
                          marginBottom: "15px",
                          padding: "10px 20px",
                          backgroundColor:
                            activePlan?.Plan === "yearly" &&
                            activePlan?.EndDate &&
                            new Date(activePlan.EndDate) > new Date()
                              ? "gray"
                              : "",
                          cursor:
                            activePlan?.Plan === "yearly" &&
                            activePlan?.EndDate &&
                            new Date(activePlan.EndDate) > new Date()
                              ? "not-allowed"
                              : "pointer",
                        }}
                      >
                        Purchase Annually @ ₹2500
                      </button>
                    </div>
                  )}
                </Card>
              </Col>
            </Row>
          </Container>
        )
      }}
    </CommonLogic>
  )
}

export default connect(null, { setBreadcrumbItems })(SubscriptionPlans)
