import React, { useState, useEffect } from "react"
import {
  Button,
  Label,
  Input,
  Row,
  Col,
  Alert,
  Table,
  Card,
  CardBody,
  CardTitle,
  Pagination,
  PaginationItem,
  PaginationLink,
  Spinner,
} from "reactstrap"
import axios from "axios"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "store/actions"
import CommonLogic from "components/Common/CommonLogic"

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const PositiveReviewData = props => {
  document.title = "PositiveReviewData | PostReview"

  const breadcrumbItems = [
    { title: "PostReview", link: "#" },
    { title: "Positive Review Data", link: "PositiveReviewData" },
  ]

  // State Variables
  const [reviews, setReviews] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [searchTerm, setSearchTerm] = useState("")
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" })
  const [currentPage, setCurrentPage] = useState(1)
  const [reviewsPerPage, setReviewsPerPage] = useState(10) // Default to 10 reviews per page

  const [merchantId, setMerchantId] = useState(null)

  useEffect(() => {
    props.setBreadcrumbItems("Positive Review Data", breadcrumbItems)

    // Only fetch reviews if merchantId is not null
    if (merchantId) {
      fetchReviews()
    }
  }, [merchantId])

  // Fetch Reviews (Only Positive)
  const fetchReviews = async () => {
    if (!merchantId) return // Prevent API call if merchantId is null

    try {
      setLoading(true)
      const response = await axios.get(
        `${API_BASE_URL}/aireviews/merchant/${merchantId}`,
      )

      const { positiveReviews } = response.data // Only fetch positive reviews
      if (positiveReviews) {
        setReviews(positiveReviews)
      } else {
        setReviews([])
      }
    } catch (err) {
      setError("Failed to fetch positive reviews. Please try again later.")
    } finally {
      setLoading(false)
    }
  }

  // Sorting Functionality

  const handleSort = columnKey => {
    let direction = "asc"
    if (sortConfig.key === columnKey && sortConfig.direction === "asc") {
      direction = "desc"
    }
    setSortConfig({ key: columnKey, direction })

    const sortedData = [...reviews].sort((a, b) => {
      const aValue = a[columnKey] || 0 // Default to 0 if the value is undefined
      const bValue = b[columnKey] || 0

      if (aValue < bValue) return direction === "asc" ? -1 : 1
      if (aValue > bValue) return direction === "asc" ? 1 : -1
      return 0
    })

    setReviews(sortedData)
  }

  const getSortIndicator = columnKey => {
    if (sortConfig.key === columnKey) {
      return sortConfig.direction === "asc" ? "↑" : "↓"
    }
    return null
  }

  const filteredReviews = reviews.filter(review => {
    const searchLower = searchTerm.toLowerCase()
    const name = review.name ? review.name.toLowerCase() : "" // Handle undefined name
    const mobileNo = review.mobileNo ? review.mobileNo.toLowerCase() : "" // Handle undefined mobileNo

    return name.includes(searchLower) || mobileNo.includes(searchLower)
  })

  // // Pagination Logic
  // const indexOfLastReview = currentPage * reviewsPerPage
  // const indexOfFirstReview = indexOfLastReview - reviewsPerPage
  // const currentReviews = filteredReviews.slice(
  //   indexOfFirstReview,
  //   indexOfLastReview,
  // )

  // const paginate = pageNumber => setCurrentPage(pageNumber)

  // const pageNumbers = []
  // for (
  //   let i = 1;
  //   i <= Math.ceil(filteredReviews.length / reviewsPerPage);
  //   i++
  // ) {
  //   pageNumbers.push(i)
  // }

  // Pagination Logic
  const indexOfLastReview = currentPage * reviewsPerPage
  const indexOfFirstReview = indexOfLastReview - reviewsPerPage
  const currentReviews = filteredReviews.slice(
    indexOfFirstReview,
    indexOfLastReview,
  )

  const paginate = pageNumber => setCurrentPage(pageNumber)
  const handleReviewsPerPageChange = event => {
    setReviewsPerPage(Number(event.target.value))
    setCurrentPage(1) // Reset to first page when changing page size
  }

  const pageNumbers = []
  for (
    let i = 1;
    i <= Math.ceil(filteredReviews.length / reviewsPerPage);
    i++
  ) {
    pageNumbers.push(i)
  }

  const StarRating = ({ rating }) => {
    const totalStars = 5
    return (
      <>
        {Array.from({ length: totalStars }, (_, index) => (
          <i
            className="mdi mdi-star"
            key={index}
            style={{
              fontSize: "20px",
              color: index < rating ? "gold" : "#BEBEBE",
              marginRight: "3px",
            }}
          />
        ))}
      </>
    )
  }
  return (
    <CommonLogic>
      {({ merchantData, loading, error: merchantError }) => {
        if (loading)
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
              }}
            >
              <Spinner
                size="xg"
                className="me-2"
                style={{ color: "#2B3A4A" }}
              />
              Loading...
            </div>
          )
        if (merchantError) return <Alert color="danger">{merchantError}</Alert>
        // if (!merchantData || !merchantData._id)
        //   return <Alert color="warning">Merchant data is not available.</Alert>

        // Set the merchant ID once it's available
        if (merchantData?._id !== merchantId) {
          setMerchantId(merchantData?._id)
        }
        console.log("merchantId", merchantId)
        return (
          <div>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "50vh",
                }}
              >
                <Spinner
                  size="xg"
                  className="me-2"
                  style={{ color: "#2B3A4A" }}
                />
                Loading...
              </div>
            ) : (
              <>
                <Row>
                  <Card>
                    <CardBody>
                      <Row className="mb-4">
                        <Col lg={6}>
                          <CardTitle className="h4">Positive Reviews</CardTitle>
                          <p className="card-title-desc">
                            Review and manage positive feedback efficiently.
                          </p>
                        </Col>
                        <Col lg={4} className="text-lg-right">
                          <Input
                            type="text"
                            placeholder="Search by Name or Mobile"
                            value={searchTerm}
                            onChange={e => setSearchTerm(e.target.value)}
                          />
                        </Col>
                      </Row>

                      {loading ? (
                        <Spinner size="lg" color="primary" />
                      ) : currentReviews.length > 0 ? (
                        <div className="table-responsive">
                          <Table className="table table-bordered table-striped mb-0 d-none d-lg-table">
                            <thead>
                              <tr>
                                <th
                                  style={{
                                    backgroundColor: "#7a6fbe",
                                    color: "white",
                                  }}
                                >
                                  No.
                                </th>
                                <th
                                  style={{
                                    backgroundColor: "#7a6fbe",
                                    color: "white",
                                    width: "60%", // Decrease the width for reviews column
                                    whiteSpace: "nowrap", // Prevent text wrapping
                                  }}
                                >
                                  Review
                                </th>
                                <th
                                  style={{
                                    backgroundColor: "#7a6fbe",
                                    color: "white",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleSort("ratings")}
                                >
                                  Rating {getSortIndicator("ratings")}
                                </th>
                                <th
                                  onClick={() => handleSort("createdAt")}
                                  style={{
                                    backgroundColor: "#7a6fbe",
                                    color: "white",
                                  }}
                                >
                                  Date {getSortIndicator("createdAt")}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {currentReviews.map((review, index) => (
                                <tr key={review._id}>
                                  <td>{indexOfFirstReview + index + 1}</td>
                                  <td
                                    style={{
                                      maxWidth: "100px", // Limit the width for reviews column
                                      overflow: "hidden",
                                      textOverflow: "ellipsis", // Add ellipsis for overflowed text
                                    }}
                                  >
                                    {review.generateAIReview}
                                  </td>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <StarRating
                                        rating={review.ratings || 0}
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    {review.createdAt}
                                    {/* {new Date(
                                      review.createdAt,
                                    ).toLocaleDateString()} */}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>

                          {/* For small screens */}
                          <div className="d-lg-none">
                            {currentReviews?.map((review, index) => (
                              <Card key={review._id} className="mb-3">
                                <CardBody>
                                  <Row>
                                    <Col xs={4}>
                                      <strong>No:</strong>
                                    </Col>
                                    <Col xs={8}>
                                      {indexOfFirstReview + index + 1}
                                    </Col>
                                  </Row>
                                  <Row className="mt-2">
                                    <Col xs={4}>
                                      <strong>Review:</strong>
                                    </Col>
                                    <Col xs={8}>
                                      <div
                                        style={{
                                          maxWidth: "100px", // Limit the width for reviews column
                                          overflow: "hidden",
                                          textOverflow: "ellipsis", // Add ellipsis for overflowed text
                                        }}
                                      >
                                        {review.generateAIReview}
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row className="mt-2">
                                    <Col xs={4}>
                                      <strong>Rating:</strong>
                                    </Col>
                                    <Col xs={8}>
                                      <StarRating
                                        rating={review.ratings || 0}
                                      />
                                    </Col>
                                  </Row>
                                  <Row className="mt-2">
                                    <Col xs={4}>
                                      <strong>Date:</strong>
                                    </Col>
                                    <Col xs={8}>
                                      {review.createdAt}
                                      {/* {new Date(
                                        review.createdAt,
                                      ).toLocaleDateString()} */}
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                            ))}
                          </div>
                        </div>
                      ) : (
                        <Alert color="warning">
                          No Positive Reviews Found.
                        </Alert>
                      )}

                      <div>
                        {/* Pagination Controls */}
                        <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mt-3 gap-2">
                          {/* Reviews Per Page Dropdown */}
                          <div className="d-flex align-items-center gap-2">
                            <span>Show</span>
                            <select
                              value={reviewsPerPage}
                              onChange={handleReviewsPerPageChange}
                              className="form-select w-auto"
                            >
                              <option value={10}>10</option>
                              <option value={20}>20</option>
                              <option value={50}>50</option>
                              <option value={100}>100</option>
                            </select>
                            <span>reviews per page</span>
                          </div>

                          {/* Pagination */}
                          <Pagination aria-label="Page navigation">
                            <PaginationItem disabled={currentPage === 1}>
                              <PaginationLink
                                first
                                onClick={() => paginate(1)}
                              />
                            </PaginationItem>
                            <PaginationItem disabled={currentPage === 1}>
                              <PaginationLink
                                previous
                                onClick={() => paginate(currentPage - 1)}
                              />
                            </PaginationItem>

                            {pageNumbers.map(number => (
                              <PaginationItem
                                active={number === currentPage}
                                key={number}
                              >
                                <PaginationLink
                                  onClick={() => paginate(number)}
                                >
                                  {number}
                                </PaginationLink>
                              </PaginationItem>
                            ))}

                            <PaginationItem
                              disabled={currentPage === pageNumbers.length}
                            >
                              <PaginationLink
                                next
                                onClick={() => paginate(currentPage + 1)}
                              />
                            </PaginationItem>
                            <PaginationItem
                              disabled={currentPage === pageNumbers.length}
                            >
                              <PaginationLink
                                last
                                onClick={() => paginate(pageNumbers.length)}
                              />
                            </PaginationItem>
                          </Pagination>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Row>
              </>
            )}
          </div>
        )
      }}
    </CommonLogic>
  )
}

export default connect(null, { setBreadcrumbItems })(PositiveReviewData)
