import React, { useState, useEffect } from "react"
import { Button, Label, Input, FormFeedback, Row, Col, Alert } from "reactstrap"
import CommonLogic from "../../components/Common/CommonLogic"
import { Badge } from "reactstrap"
import {
  Table,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Spinner,
} from "reactstrap"
import axios from "axios"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "store/actions"

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
const NegativeReviewData = props => {
  document.title = "NegativeReviewData | PostReview"
  const breadcrumbItems = [
    { title: "PostReview", link: "#" },
    { title: "Negative Review Data", link: "NegativeReviewData" },
  ]
  const [reviews, setReviews] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [merchantId, setMerchantId] = useState(null)
  const [modal, setModal] = useState(false)
  const [selectedReview, setSelectedReview] = useState(null)
  const [resolutionDetails, setResolutionDetails] = useState("")
  const [status, setStatus] = useState("Open")
  const [searchTerm, setSearchTerm] = useState("")
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" })
  // const [currentPage, setCurrentPage] = useState(1)
  // const [reviewsPerPage] = useState(5)
  const [currentPage, setCurrentPage] = useState(1)
  const [reviewsPerPage, setReviewsPerPage] = useState(10) // Default to 10 reviews per page

  const toggleModal = () => setModal(!modal)

  const markAsResolved = review => {
    setSelectedReview(review)
    setStatus(review.status || "Open") // Set pre-populated status
    setResolutionDetails(review.resolutionComment || "") // Set pre-populated resolution details
    toggleModal()
  }

  const handleResolve = async () => {
    if (selectedReview) {
      try {
        // Send the update request to the API
        const response = await axios.put(
          `${API_BASE_URL}/updateReviews/${selectedReview._id}`,
          {
            status: status,
            resolutionComment: resolutionDetails,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          },
        )

        // Check if the update was successful
        if (response.status === 200) {
          // Update the reviews state with the updated review data
          const updatedReviews = reviews.map(review =>
            review._id === selectedReview._id
              ? {
                  ...review,
                  status: status,
                  resolutionComment: resolutionDetails,
                }
              : review,
          )
          setReviews(updatedReviews)

          // Close the modal
          toggleModal()

          // Reset resolution details and status
          setResolutionDetails("")
          setStatus("Open")
        } else {
          console.error("Failed to update review:", response.data.message)
        }
      } catch (error) {
        console.error(
          "Error updating review:",
          error.response?.data || error.message,
        )
        alert("An error occurred while updating the review. Please try again.")
      }
    }
  }

  useEffect(() => {
    props.setBreadcrumbItems("Negative Review Data", breadcrumbItems)
    if (merchantId !== null) {
      fetchReviews()
    }
  }, [merchantId])
  const fetchReviews = async () => {
    try {
      setLoading(true)
      const response = await axios.get(
        `${API_BASE_URL}/negativeReviews/${merchantId}`,
      )
      console.log("Fetched reviews:", response.data) // Log the response
      if (Array.isArray(response.data)) {
        setReviews(response.data) // If it's an array, set it directly
      } else {
        setReviews([response.data]) // If it's an object, wrap it in an array
      }
    } catch (err) {
      setError("Failed to fetch reviews. Please try again later.")
      console.error("Error fetching reviews:", err) // Log any error
    } finally {
      setLoading(false)
    }
  }
  const handleSort = columnKey => {
    let direction = "asc"
    if (sortConfig.key === columnKey && sortConfig.direction === "asc") {
      direction = "desc"
    }
    setSortConfig({ key: columnKey, direction })

    const sortedData = [...reviews].sort((a, b) => {
      if (a[columnKey] < b[columnKey]) return direction === "asc" ? -1 : 1
      if (a[columnKey] > b[columnKey]) return direction === "asc" ? 1 : -1
      return 0
    })

    setReviews(sortedData)
  }
  const getSortIndicator = columnKey => {
    if (sortConfig.key === columnKey) {
      return sortConfig.direction === "asc" ? "↑" : "↓"
    }
    return null
  }

  // Sort reviews by date in descending order (LIFO)
  const sortedReviews = Array.isArray(reviews)
    ? reviews.sort((a, b) => new Date(b.date) - new Date(a.date))
    : []
  const filteredReviews = sortedReviews.filter(review => {
    const searchLower = searchTerm.toLowerCase()
    return (
      review.name.toLowerCase().includes(searchLower) || // Check if name matches
      review.mobileNo?.toLowerCase().includes(searchLower) // Check if mobile number matches
    )
  })

  const getStatusColor = status => {
    switch (status) {
      case "Open":
        return "bg-danger text-white"
      case "On Hold":
        return "bg-warning text-dark"
      case "Escalated":
        return "bg-warning text-dark"
      case "Resolved":
        return "bg-success text-white"
      case "Closed":
        return "bg-secondary text-white"
      case "Pushed to QA":
        return "bg-success text-dark" // Adjust as needed
      default:
        return "bg-light text-dark"
    }
  }
  // Pagination Logic
  const indexOfLastReview = currentPage * reviewsPerPage
  const indexOfFirstReview = indexOfLastReview - reviewsPerPage
  const currentReviews = filteredReviews.slice(
    indexOfFirstReview,
    indexOfLastReview,
  )

  const paginate = pageNumber => setCurrentPage(pageNumber)
  const handleReviewsPerPageChange = event => {
    setReviewsPerPage(Number(event.target.value))
    setCurrentPage(1) // Reset to first page when changing page size
  }

  const pageNumbers = []
  for (
    let i = 1;
    i <= Math.ceil(filteredReviews.length / reviewsPerPage);
    i++
  ) {
    pageNumbers.push(i)
  }
  const StarRating = ({ rating }) => {
    const totalStars = 5
    return (
      <>
        {Array.from({ length: totalStars }, (_, index) => (
          <i
            className="mdi mdi-star"
            key={index}
            style={{
              fontSize: "20px",
              color: index < rating ? "gold" : "#BEBEBE",
              marginRight: "3px",
            }}
          />
        ))}
      </>
    )
  }

  return (
    <CommonLogic>
      {({ merchantData, loading, error: merchantError }) => {
        if (loading)
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
              }}
            >
              <Spinner
                size="xg"
                className="me-2"
                style={{ color: "#2B3A4A" }}
              />
              Loading...
            </div>
          )
        if (merchantError) return <Alert color="danger">{merchantError}</Alert>
        // if (!merchantData || !merchantData._id)
        //   return <Alert color="warning">Merchant data is not available.</Alert>

        // Set the merchant ID once it's available
        if (merchantData._id !== merchantId) {
          setMerchantId(merchantData._id)
        }

        return (
          <div>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "50vh",
                }}
              >
                <Spinner
                  size="xg"
                  className="me-2"
                  style={{ color: "#2B3A4A" }}
                />
                Loading...
              </div>
            ) : (
              <>
                <Row>
                  <Col lg={12}>
                    <Card>
                      <CardBody>
                        <Row className="mb-4">
                          <Col lg={6}>
                            <CardTitle className="h4">
                              Negative Reviews Data
                            </CardTitle>
                            <p className="card-title-desc">
                              Review and manage negative feedback efficiently.
                            </p>
                          </Col>
                          <Col lg={4} className="text-lg-right">
                            <Input
                              type="text"
                              placeholder="Search by Name"
                              value={searchTerm}
                              onChange={e => setSearchTerm(e.target.value)}
                            />
                          </Col>
                        </Row>

                        {currentReviews && currentReviews.length > 0 ? (
                          <div className="table-responsive">
                            <Table className="table mb-0 d-none d-lg-table">
                              <thead>
                                <tr>
                                  <th
                                    style={{
                                      backgroundColor: "#7a6fbe",
                                      color: "white",
                                    }}
                                  >
                                    No.
                                  </th>
                                  <th
                                    style={{
                                      backgroundColor: "#7a6fbe",
                                      color: "white",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleSort("name")}
                                  >
                                    Name {getSortIndicator("name")}
                                  </th>
                                  <th
                                    style={{
                                      backgroundColor: "#7a6fbe",
                                      color: "white",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleSort("mobileNo")}
                                  >
                                    Mobile {getSortIndicator("mobileNo")}
                                  </th>
                                  <th
                                    style={{
                                      backgroundColor: "#7a6fbe",
                                      color: "white",
                                    }}
                                  >
                                    Review
                                  </th>
                                  <th
                                    style={{
                                      backgroundColor: "#7a6fbe",
                                      color: "white",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleSort("rating")}
                                  >
                                    Rating {getSortIndicator("rating")}
                                  </th>
                                  <th
                                    style={{
                                      backgroundColor: "#7a6fbe",
                                      color: "white",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleSort("createdAt")}
                                  >
                                    Date {getSortIndicator("createdAt")}
                                  </th>
                                  <th
                                    style={{
                                      backgroundColor: "#7a6fbe",
                                      color: "white",
                                    }}
                                  >
                                    Action Status
                                  </th>
                                  <th
                                    style={{
                                      backgroundColor: "#7a6fbe",
                                      color: "white",
                                    }}
                                  >
                                    Action Taken
                                  </th>
                                  <th
                                    style={{
                                      backgroundColor: "#7a6fbe",
                                      color: "white",
                                    }}
                                  >
                                    Action
                                  </th>
                                </tr>
                              </thead>

                              <tbody>
                                {currentReviews.map((review, index) => (
                                  <tr key={review._id || index}>
                                    <th scope="row">
                                      {indexOfFirstReview + index + 1}
                                    </th>
                                    <td>{review.name || "N/A"}</td>
                                    <td>{review.mobileNo || "N/A"}</td>
                                    <td className="col-3">
                                      {review.aigeneratedreview ||
                                        "No Review Available"}
                                    </td>
                                    <td>
                                      <div className="d-flex justify-content-start align-items-center">
                                        <StarRating
                                          rating={review.rating || 0}
                                        />{" "}
                                        {/* Single-line star rating */}
                                      </div>
                                    </td>
                                    <td>
                                      {review.createdAt
                                        ? new Date(
                                            review.createdAt,
                                          ).toLocaleDateString() // Show only the date
                                        : "No Date Available"}
                                    </td>
                                    {/* <td
                                      className={getStatusColor(review.status)}
                                    >
                                      {review.status || "N/A"}
                                    </td> */}
                                    <td>
                                      <Badge
                                        className={`p-2 px-3 ${getStatusColor(review.status)}`}
                                        style={{
                                          borderRadius: "50px", // Ensures rounded shape
                                          fontWeight: "bold",
                                          minWidth: "120px",
                                          textAlign: "center",
                                          display: "inline-block",
                                        }}
                                      >
                                        {review.status || "N/A"}
                                      </Badge>
                                    </td>

                                    <td>{review.resolutionComment || "N/A"}</td>
                                    <td>
                                      <Button
                                        color="link"
                                        onClick={() => markAsResolved(review)}
                                        title="Mark as Resolved"
                                      >
                                        <i
                                          className="mdi mdi-pencil"
                                          style={{
                                            fontSize: "20px",
                                            color: "gray",
                                          }}
                                        ></i>
                                      </Button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>

                            {/* Responsive View for Small Screens */}
                            <div className="d-lg-none">
                              {currentReviews.map((review, index) => (
                                <Card
                                  key={review._id || index}
                                  className="mb-3"
                                >
                                  <CardBody>
                                    <Row>
                                      <Col xs={4}>
                                        <strong>No:</strong>
                                      </Col>
                                      <Col xs={8}>
                                        {indexOfFirstReview + index + 1}
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col xs={4}>
                                        <strong>Name:</strong>
                                      </Col>
                                      <Col xs={8}>{review.name || "N/A"}</Col>
                                    </Row>
                                    <Row>
                                      <Col xs={4}>
                                        <strong>Mobile:</strong>
                                      </Col>
                                      <Col xs={8}>
                                        {review.mobileNo || "N/A"}
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col xs={4}>
                                        <strong>Review:</strong>
                                      </Col>
                                      <Col xs={8}>
                                        {review.aigeneratedreview ||
                                          "No Review Available"}
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col xs={4}>
                                        <strong>Rating:</strong>
                                      </Col>
                                      <Col xs={8}>
                                        <StarRating
                                          rating={review.rating || 0}
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col xs={4}>
                                        <strong>Date:</strong>
                                      </Col>
                                      <Col xs={6}>
                                        {review.createdAt
                                          ? new Date(
                                              review.createdAt,
                                            ).toLocaleString()
                                          : "No Date Available"}
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col xs={4}>
                                        <strong>Status:</strong>
                                      </Col>
                                      <Col
                                        xs={8}
                                        className={getStatusColor(
                                          review.status,
                                        )}
                                      >
                                        {review.status || "N/A"}
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col xs={4}>
                                        <strong>Action Taken:</strong>
                                      </Col>
                                      <Col xs={8}>
                                        {review.resolutionComment}
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col xs={4}>
                                        <strong>Action:</strong>
                                      </Col>
                                      <Col xs={8}>
                                        {/* <Button
                                          color="link"
                                          onClick={() => markAsResolved(review)}
                                          title="Mark as Resolved"
                                        >
                                          <i
                                            className="mdi mdi-pencil"
                                            style={{
                                              fontSize: "20px",
                                              color: "gray",
                                            }}
                                          ></i>
                                        </Button> */}
                                        <Button
                                          color="link"
                                          onClick={() => markAsResolved(review)}
                                          title="Mark as Resolved"
                                        >
                                          <i
                                            className="mdi mdi-pencil"
                                            style={{
                                              fontSize: "20px",
                                              color: "gray",
                                            }}
                                          ></i>
                                        </Button>
                                      </Col>
                                    </Row>
                                  </CardBody>
                                </Card>
                              ))}
                            </div>
                          </div>
                        ) : (
                          <Alert color="warning">
                            No Negative Review Found
                          </Alert>
                        )}

                        <div>

                          {/* Pagination Controls */}
                          <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mt-3 gap-2">
                            {/* Reviews Per Page Dropdown */}
                            <div className="d-flex align-items-center gap-2">
                              <span>Show</span>
                              <select
                                value={reviewsPerPage}
                                onChange={handleReviewsPerPageChange}
                                className="form-select w-auto"

                              >
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                              </select>
                              <span>reviews per page</span>
                            </div>

                            {/* Pagination */}
                            <Pagination aria-label="Page navigation">
                              <PaginationItem disabled={currentPage === 1}>
                                <PaginationLink

                                  first
                                  onClick={() => paginate(1)}
                                />
                              </PaginationItem>
                              <PaginationItem disabled={currentPage === 1}>
                                <PaginationLink
                                  previous
                                  onClick={() => paginate(currentPage - 1)}
                                />
                              </PaginationItem>

                              {pageNumbers.map(number => (
                                <PaginationItem
                                  active={number === currentPage}
                                  key={number}
                                >
                                  <PaginationLink
                                    onClick={() => paginate(number)}
                                  >
                                    {number}
                                  </PaginationLink>
                                </PaginationItem>
                              ))}

                              <PaginationItem
                                disabled={currentPage === pageNumbers.length}
                              >
                                <PaginationLink

                                  next
                                  onClick={() => paginate(currentPage + 1)}
                                />
                              </PaginationItem>
                              <PaginationItem
                                disabled={currentPage === pageNumbers.length}
                              >
                                <PaginationLink
                                  last
                                  onClick={() => paginate(pageNumbers.length)}
                                />
                              </PaginationItem>
                            </Pagination>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                <Modal
                  isOpen={modal}
                  toggle={() => {
                    toggleModal()
                    setStatus(selectedReview?.status || "Open")
                    setResolutionDetails(
                      selectedReview?.resolutionComment || "",
                    )
                  }}
                >
                  <ModalHeader toggle={toggleModal}>Resolve Review</ModalHeader>
                  <ModalBody>
                    <Label>Status:</Label>
                    <Badge
                      className={`p-2 px-3 ${getStatusColor(status)}`}
                      style={{
                        borderRadius: "50px", // Makes it pill-shaped
                        fontWeight: "bold",
                        minWidth: "120px",
                        textAlign: "center",
                        display: "inline-block",
                      }}
                    >
                      {status}
                    </Badge>

                    <Input
                      type="select"
                      className="mt-2"
                      value={status}
                      onChange={e => setStatus(e.target.value)}
                    >
                      <option value="Open">Open</option>
                      <option value="On Hold">On Hold</option>
                      <option value="Escalated">Escalated</option>
                      <option value="Resolved">Resolved</option>
                      <option value="Closed">Closed</option>
                    </Input>

                    <Label className="mt-2">Resolution Details:</Label>
                    <Input
                      type="textarea"
                      value={resolutionDetails}
                      onChange={e => setResolutionDetails(e.target.value)}
                    />
                  </ModalBody>

                  <ModalFooter>
                    <Button color="primary" onClick={handleResolve}>
                      Submit
                    </Button>
                    <Button color="secondary" onClick={toggleModal}>
                      Cancel
                    </Button>
                  </ModalFooter>
                </Modal>
              </>
            )}
          </div>
        )
      }}
    </CommonLogic>
  )
}

export default connect(null, { setBreadcrumbItems })(NegativeReviewData)
